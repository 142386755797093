import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { ModelViewer } from "../components/ModelViewer";
import ApplicationTable from "../components/Table";

import fleet from "../data/fleet";

export default function ItemDisplay() {
  const { id } = useParams();

  const vehicle = fleet.find((item) => item.slug === id);

  return (
    <div className="grid min-h-screen grid-rows-[auto_1fr_auto]">
      <Header />
      <div className="flex flex-col xl:flex-row h-full bg-[#FEEEDC]">
        <div className="h-full w-full xl:w-[55%] bg-[#ffffff] min-h-[300px] relative cursor-grab">
          <div className="absolute right-5 bottom-2  z-10 bg-white bg-opacity-75 px-2 py-1 text-sm rounded shadow uppercase">
            View car in 3d
          </div>
          <ModelViewer bgColor="#ffffff" modelUrl={vehicle?.model!} />
        </div>
        <div className="h-full w-full xl:w-[45%] p-4 xl:p-10 flex flex-col">
          <div className="text-[24px] md:text-[32px]">{vehicle?.name}</div>
          <div>
            <ApplicationTable
              description={vehicle?.description}
              applications={vehicle?.table.map((k) => ({
                id: k[0],
                leasePrinciple: k[1],
                expiredAmount: k[2],
              }))}
            />
          </div>
          <div className="flex items-center justify-between mt-auto">
            <div className="text-[24px] md:text-[32px]">{vehicle?.price}</div>
            {/* <button className="text-[rgb(14,36,37)] items-center shadow-[0px_5px_rgb(58,146,141)] text-lg font-semibold inline-flex px-6 focus:outline-none justify-center text-center bg-[rgb(126,202,193)] focus:bg-[rgb(58,146,141)] border-[rgb(58,146,141)] ease-in-out duration-300 outline-none hover:bg-[rgb(83,174,166)] hover:text-white focus:text-white focus:shadow-none border-2 py-3 rounded-lg h-16 tracking-wide focus:translate-y-1 w-auto">
              Contact Us
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
