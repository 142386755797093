const DigitalFinancialFrontier = () => {
  return (
    <div className="relative p-4 sm:p-8 lg:p-20 items-center gap-6 sm:gap-12 h-full bg-[#FEEEDC] mb-8 sm:mb-12">
      <div className="text-center max-w-4xl mx-auto">
        <p className="text-2xl sm:text-3xl lg:text-5xl font-medium text-black">
          Tokenomics
        </p>
      </div>
      <div className="relative items-center w-full mx-auto mt-6 sm:mt-12"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-12 mt-6 sm:mt-12">
        <FeatureCard
          title="LP Burned"
          description="Launched through pump fun, LP is automatically burned"
        />
        <FeatureCard
          title="Ownership Renounced"
          description="Ownership of the contract has been renounced, making it a community token"
        />
        <FeatureCard
          title="Supply 1 BN"
          description="The total supply of $EXOTIX is 1 billion tokens"
        />
      </div>
    </div>
  );
};

const FeatureCard = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div className="text-left sm:text-center">
    <h2 className="text-2xl sm:text-3xl text-black font-medium">{title}</h2>
    <p className="text-base sm:text-lg tracking-wide text-black mt-2 sm:mt-4">
      {description}
    </p>
  </div>
);

export default DigitalFinancialFrontier;
