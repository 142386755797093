interface Application {
  id: string;
  leasePrinciple: string;
  expiredAmount: string;
}

interface ApplicationTableProps {
  applications: Application[] | undefined;
  description?: string;
}

const ApplicationTable: React.FC<ApplicationTableProps> = ({
  applications,
  description,
}) => {
  return (
    <div>
      <div className="text-[18px] md:text-[24] mt-4 opacity-50">
        {description}
      </div>
      <table className="min-w-full divide-y-2 divide-black w-full mt-4">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 px-2 sm:px-3 text-left text-xs sm:text-sm font-medium text-black"
            >
              <span className="hidden sm:inline">Metric </span>
            </th>
            <th
              scope="col"
              className="py-3.5 px-2 sm:px-3 text-left text-xs sm:text-sm font-medium text-black"
            >
              Value
            </th>
            <th
              scope="col"
              className="py-3.5 px-2 sm:px-3 text-left text-xs sm:text-sm font-medium text-black"
            >
              Description
            </th>
          </tr>
        </thead>
        <tbody className="divide-y-2 divide-black">
          {applications && applications.map((app) => <TableRow app={app} />)}
        </tbody>
      </table>
    </div>
  );
};

const TableRow: React.FC<{ app: Application }> = ({ app }) => {
  return (
    <tr key={app.id} className="[&>td]:py-2 sm:[&>td]:py-4">
      <td className="whitespace-nowrap px-2 sm:px-3 text-xs sm:text-sm text-black">
        <span className="block">{app.id}</span>
      </td>
      <td className="whitespace-nowrap px-2 sm:px-3 text-xs sm:text-sm text-black">
        {app.leasePrinciple}
      </td>
      <td className="whitespace-nowrap px-2 sm:px-3 text-xs sm:text-sm text-black">
        {app.expiredAmount}
      </td>
    </tr>
  );
};

export default ApplicationTable;
