import Fleet from "../components/Fleet";
import Header from "../components/Header";
import DigitalFinancialFrontier from "../components/DigitalFinancialFrontier";
import FAQ from "../components/Faq";

export default function Home() {
  return (
    <div>
      <Header />
      <div className="py-8 md:py-[100px] px-4 md:px-[43px] mb-5 bg-[#FEEEDC] border-black border-b">
        <div className="text-[#8F8F8F] text-xl md:text-2xl lg:text-[32px] font-bold">
          $EXOTIX{" "}
          <span className="text-xl md:text-2xl lg:text-[32px] text-black font-normal ml-2">
            AS SEEN ON GTA VC
          </span>
        </div>
        <div className="text-4xl md:text-6xl lg:text-[96px] max-w-full md:max-w-[15ch] leading-tight md:leading-none mt-5 mb-8">
          EXOTIX Rentals
          <br /> on Solana
        </div>
        <div className="text-lg md:text-xl lg:text-[24px] max-w-full md:max-w-[80%] lg:max-w-[64%]">
          Flex on people as if you’re a billionaire with our exquisite
          collection of cars at affordable prices through $EXOTIX. <br /> As
          seen on Tai Lopez’s garage and featured on top gear. The coin will be
          launched through pump fun.
        </div>
      </div>
      <Fleet />
      <DigitalFinancialFrontier />
      <FAQ
        faqs={[
          {
            answer:
              "Upto you if you have money and connections to get away from a DUI if you're caught.",
            question: "Can I drive drunk?",
          },
          {
            answer:
              "Once it goes to Raydium, there will be new cars launched on the platform. Each car will be 3D, driveable and customize-able. There will be a total of 112 cars that can bought only with $EXOTIX",
            question: "What is the future of this token?",
          },
          {
            answer:
              "After customizing your car, you can list it on solana EXOTIX. You can also rent it out to other users. How you modify your car to be attractive matters. Or you can pump $EXOTIX and SELL it for profit.",
            question: "How much money can I make?",
          },
          {
            question: "Will you ignore me if I have a low credit score?",
            answer: "No, but we will if you have no $EXOTIX",
          },
        ]}
      />
      <div className="md:min-h-[120px] p-4 text-xl md:text-2xl lg:text-[32px] mt-6 px-4 md:px-10 flex items-center justify-between bg-[#C8BEFD]">
        <div>Solana EXOTIX</div>
      </div>
    </div>
  );
}
