import React, { useState } from "react";
import { pumpFunLink, telegramLink } from "../data/links";
import { useNavigate } from "react-router-dom";

const Buttons = [
  { name: "Home", link: "/" },
  { name: "Pumpfun", link: pumpFunLink },
  { name: "Telegram", link: telegramLink },
];

export default function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const BuyButton = () => (
    <div
      onClick={() => window.open(pumpFunLink, "_blank")}
      className="text-base md:text-lg lg:text-xl font-semibold font-[Condensed] flex items-center justify-center hover:bg-white border-b border-black md:border-l hover:text-black transition-all bg-black h-[60px] md:h-auto md:min-h-full w-full md:w-[190px] cursor-pointer text-white"
    >
      Buy $EXOTIX Coin
    </div>
  );

  return (
    <div>
      <div>
        <div className="min-h-[80px] md:min-h-[120px] font-[Condensed] bg-[#C8BEFD] w-full border-black border-b text-3xl md:text-5xl lg:text-[64px] font-extrabold text-center py-4 flex justify-between items-center px-4">
          <button className="md:hidden" onClick={toggleSidebar}>
            ☰
          </button>
          SOLANA EXOTIX
          <div className="w-8 md:hidden"></div> {/* Placeholder for symmetry */}
        </div>
        <div className="w-full md:hidden">
          <BuyButton />
        </div>
      </div>

      {/* Sidebar for mobile */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-[#C8BEFD] z-50 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:hidden`}
      >
        <div className="flex flex-col items-start pt-14 px-4">
          {Buttons.map((button) => (
            <div
              key={button.name}
              onClick={() => window.open(button.link, "_blank")}
              className="text-lg cursor-pointer hover:underline py-4 font-light text-black w-full"
            >
              {button.name}
            </div>
          ))}
        </div>
      </div>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Desktop menu */}
      <div className="hidden md:flex flex-row">
        <div className="flex flex-wrap items-center justify-start px-[22px] bg-[#C8BEFD] w-full border-black border-b">
          {Buttons.map((button) => (
            <div
              key={button.name}
              onClick={() => {
                if ((button.link = "/")) navigate("/");
                else window.open(button.link, "_blank");
              }}
              className="text-lg lg:text-xl cursor-pointer hover:underline px-[22px] font-light text-black flex items-center justify-center h-[87px] w-auto"
            >
              {button.name}
            </div>
          ))}
        </div>
        <BuyButton />
      </div>
    </div>
  );
}
