import React, { Suspense, useRef, useEffect, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  useGLTF,
  SpotLight,
  Environment,
  MeshReflectorMaterial,
  AccumulativeShadows,
  RandomizedLight,
  ContactShadows,
} from "@react-three/drei";
import { useControls } from "leva";
import * as THREE from "three";
import { Vector3, PerspectiveCamera as ThreePerspectiveCamera } from "three";

interface ModelProps {
  url: string;
  boundingBox?: THREE.Vector3;
}

function Model({ url, boundingBox = new THREE.Vector3(6, 6, 6) }: ModelProps) {
  const { scene } = useGLTF(url);
  const { gl } = useThree();
  const modelRef = useRef<THREE.Group>();
  const scaleRef = useRef<number>(1);
  const positionRef = useRef<THREE.Vector3>(new THREE.Vector3());

  const box = useMemo(() => new THREE.Box3().setFromObject(scene), [scene]);
  const size = useMemo(() => box.getSize(new THREE.Vector3()), [box]);

  useEffect(() => {
    if (scene) {
      const maxDim = Math.max(size.x, size.y, size.z);
      scaleRef.current = Math.min(
        boundingBox.x / size.x,
        boundingBox.y / size.y,
        boundingBox.z / size.z
      );

      box.getCenter(positionRef.current).multiplyScalar(-1);

      // Apply material updates if needed
      scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = true;
          child.receiveShadow = true;
          if (child.material) {
            const material = child.material as THREE.MeshStandardMaterial;
            material.needsUpdate = true;
            if (material.map)
              material.map.anisotropy = gl.capabilities.getMaxAnisotropy();
            if (material.normalMap)
              material.normalMap.anisotropy =
                gl.capabilities.getMaxAnisotropy();
          }
        }
      });
    }
  }, [scene, gl, boundingBox, box, size]);

  useFrame(() => {
    if (modelRef.current) {
      // Reset transformations
      scene.position.set(0, 0, 0);
      scene.rotation.set(0, 0, 0);
      scene.scale.set(1, 1, 1);

      // Compute bounding box
      const box = new THREE.Box3().setFromObject(scene);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());

      // Compute scale
      const maxDimension = Math.max(size.x, size.y, size.z);
      const scale = Math.min(
        boundingBox.x / maxDimension,
        boundingBox.y / maxDimension,
        boundingBox.z / maxDimension
      );

      // Apply transformations to the group
      modelRef.current.scale.setScalar(scale);
      modelRef.current.position.sub(center.multiplyScalar(scale));
    }
  });

  return <primitive object={scene} ref={modelRef} />;
}

function StrongLight() {
  const spotlightRef = useRef<THREE.SpotLight>(null);

  return (
    <SpotLight
      ref={spotlightRef}
      position={[5, 5, 5]}
      angle={0.6}
      penumbra={1}
      intensity={2}
      color="white"
      castShadow
      shadow-mapSize-width={2048}
      shadow-mapSize-height={2048}
      shadow-bias={-0.0001}
    />
  );
}

function SetupScene({ bgColor = "#ffffff" }: { bgColor?: string }) {
  const { scene } = useThree();

  React.useEffect(() => {
    scene.background = new THREE.Color(bgColor);
  }, [scene, bgColor]);

  return null;
}

interface ModelViewerProps {
  modelUrl: string;
  bgColor?: string;
}

function RotatingCamera() {
  const { camera } = useThree();
  const radiusRef = useRef(5);

  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    const x = Math.sin(t * 0.5) * radiusRef.current;
    const z = Math.cos(t * 0.5) * radiusRef.current;
    camera.position.set(x, 1, z);
    camera.lookAt(new Vector3(0, 0, 0));
  });

  return null;
}

function Floor() {
  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1.2, 0]} receiveShadow>
      <planeGeometry args={[30, 30]} />
      <meshBasicMaterial color={"#d3e5e8"} />
    </mesh>
  );
}

export function ModelThumbnailViewer({ modelUrl }: { modelUrl: string }) {
  return (
    <Canvas camera={{ position: [3, 1, 5] }} shadows>
      <SetupScene />
      <ambientLight intensity={2} />
      {/* <StrongLight /> */}
      <Suspense fallback={null}>
        <Model url={modelUrl} />
      </Suspense>
      <Floor />
      <ContactShadows
        resolution={512}
        position={[0, -0.01, 0]}
        opacity={1}
        scale={10}
        blur={2}
        far={0.8}
      />
      <Environment preset="city" />
      <RotatingCamera />
    </Canvas>
  );
}

export function ModelViewer({ modelUrl, bgColor }: ModelViewerProps) {
  return (
    <Canvas
      camera={{ position: [5, 0, 5], fov: 75 }}
      shadows
      gl={{
        toneMapping: THREE.ACESFilmicToneMapping,
        toneMappingExposure: 1,
      }}
    >
      <SetupScene bgColor={bgColor} />
      <ambientLight intensity={1.5} />
      <StrongLight />
      <Suspense fallback={null}>
        <Model url={modelUrl} />
      </Suspense>
      <Environment preset="sunset" />
      <OrbitControls
        minDistance={2}
        maxDistance={10}
        minPolarAngle={Math.PI / 6}
        maxPolarAngle={Math.PI - Math.PI / 6}
      />
    </Canvas>
  );
}

export { Model, StrongLight, SetupScene };
