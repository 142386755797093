const fleet = [
  {
    name: "Super Zoomer Car",
    description:
      "You think you've seen a fast car? Think again?This monster of a vehicle will take you from the trenches to a luxury yacht in a matter of milliseconds.One catch - it's fuelled by vodka.",
    tableHeaders: ["Metric", "Value", "Description"],
    table: [
      ["HP", "700", "Blaze through the highways when running from cops"],
      ["Torque", "500NM", "Enough to move a wall"],
      ["Chad Points", "10,000 per hour", "Mega Chad"],
    ],
    model: "/super_car.gltf",
    price: "25,000 $EXOTIX",
    slug: "super-zoomer-car",
  },
  {
    name: "Solops Special Plane Car",
    description:
      "The thing is with Solana, is that everything is special. Infact, our weaponized planes are road legal. With a piece like this, anyone in your way will be blastedby the jet engines, creating a completely clear path.It's like buying Shiba Inu, no limits, nothing stopping you.(Although please no alcohol consumption with this vehicle.",
    tableHeaders: ["Metric", "Value", "Description"],
    table: [
      ["HP", "12000", "No car stands in your way with this beast"],
      ["Torque", "6000NM", "Break that fucking sound barrier"],
      ["Chad Points", "25,000 per hour", "Ultra Chad"],
    ],
    model: "/f22_raptor.gltf",
    price: "100,000 $EXOTIX",
    slug: "solops-special-plane-car",
  },
  {
    name: "Solana Moto-LowriderCar",
    description:
      "Our finest, and simplest car. The Solana Moto-Lowrider. I mean, who doesn't likea lowriding car?One thing though our modified suspensions will send you to the moon (literally).",
    tableHeaders: ["Metric", "Value", "Description"],
    table: [
      ["HP", "200", "Powerful in many other ways"],
      ["Torque", "300NM", "Tough this one, tough"],
      ["Chad Points", "6,969 per hour", "Getting There Chad"],
    ],
    model: "/bmw_700.gltf",
    price: "10,000 $EXOTIX",
    slug: "solana-moto-lowridercar",
  },
  {
    name: "Heavy Duty SolShooter",
    description:
      "This shit is straight out of GTA. When you start the engine, two fully automaticAR's emerge from the bonnet and trunk, with a fully visible 360 shooting range.Not to mention that it goes fast. This one here is fuelled on Johnnie Walker's Blue Label!",
    tableHeaders: ["Metric", "Value", "Description"],
    table: [
      ["HP", "500", "Heavy and fast, Musk was involved in this"],
      ["Torque", "1000NM", "Ram your way through a metal safe"],
      ["Chad Points", "8,000 per hour", "Decent Enough Chad"],
    ],
    model: "/bmw_m3.gltf",
    price: "17,500 $EXOTIX",
    slug: "heavy-duty-solshooter",
  },
  {
    name: "Big Black Solana Car",
    description:
      "I mean, if you don't wanna lose your girl to a BBC you could always try the BB(S)CThis is unbeatable. Imagine riding down Rodeo in this magnificent thing. Pay for it with Solana, even!",
    tableHeaders: ["Metric", "Value", "Description"],
    table: [
      ["HP", "600", "Long and Girthy, but so rapid"],
      ["Torque", "900NM", "Perfect for going through blocked off tunnels"],
      ["Chad Points", "15,000 per hour", "Chuck Norris Chad"],
    ],
    price: "17,500 $EXOTIX",
    model: "datsun.gltf",
    slug: "big-black-solana-car",
  },
  {
    name: "CyberSec Solana Car",
    description:
      "This technologically advanced supercar will instantly fill your brain with many 100x and 200x coin opportunities. Riding in this car comes with it's risks ,and it's benefits! p.s. $EXOTIX is one of them!",
    tableHeaders: ["Metric", "Value", "Description"],
    table: [
      ["HP", "400", "Quick. Just quick"],
      ["Torque", "500NM", "Smash out those green dildo candles"],
      ["Chad Points", "7,500 per hour", "100x Chad"],
    ],
    price: "13,000 $EXOTIX",
    model: "./cybersec.gltf",
    slug: "cybersec-solana-car",
  },
];

export default fleet;
